import styled from "@emotion/styled";
import { css } from "@emotion/core";

import mediaqueries from "@styles/media";

/**
 * Example:
 * <Heading.h1>Lorem Ipsum</Heading.h1>
 */

const commonStyles = p => css`
  font-family: ${p.theme.fonts.title};
  font-weight: ${p.theme.fontsWeight.bold};
  color: ${p.theme.colors.primary};

  strong {
    font-family: ${p.theme.fonts.titleEm};
    text-transform: uppercase;
    font-style: italic;
    font-weight: ${p.theme.fontsWeight.regular};
  }

  em {
    font-family: ${p.theme.fonts.titleEm};
    font-style: italic;
    font-weight: ${p.theme.fontsWeight.regular};
  }
`;

const h1 = styled.h1`
  font-size: 96px;
  line-height: 1;
  letter-spacing: -3px;
  ${commonStyles};

  ${mediaqueries.desktop`
    font-size: 72px;
    line-height: 1.2;
  `};

  ${mediaqueries.tablet`
    font-size: 64px;
    line-height: 1;
  `};
`;

const h2 = styled.h2`
  font-size: 64px;
  line-height: 1;
  letter-spacing: -2px;
  ${commonStyles};

  ${mediaqueries.desktop`
    font-size: 56px;
  `};

  ${mediaqueries.tablet`
    font-size: 48px;
    line-height: 1.1;
  `};
`;

const h3 = styled.h3`
  font-size: 44px;
  line-height: 1.18;
  letter-spacing: -1px;
  ${commonStyles};

  ${mediaqueries.tablet`
    font-size: 32px;
  `};

`;

const h4 = styled.h4`
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -1px;
  ${commonStyles};

  ${mediaqueries.tablet`
    font-size: 28px;
  `};
`;

const h5 = styled.h5`
  font-size: 24px;
  line-height: 1.333;
  letter-spacing: -0.5px;
  ${commonStyles};

  ${mediaqueries.tablet`
    font-size: 20px;
  `};
`;

const h6 = styled.h6`
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  ${commonStyles};

  ${mediaqueries.tablet`
    font-size: 18px;
  `};
`;

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
};
